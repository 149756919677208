import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SelectField, TextInputFixedLabel, PhoneInputField, CheckBox } from "master-react-lib";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';






function EmergencyContact(props) {


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });


    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };



    return (

        <div className="FormContainer">


            <Grid container spacing={2}>

                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle'>Emergency contact</h1>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="First name *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Last name *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone.number}
                            countryCodeEditable={false}
                            onChange={(phone, country) => console.log("onChange", phone, country)}
                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone number *"
                            LabelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Email address *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={8}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Mailing street address *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Apt/Floor (Optional)"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <SelectField
                            label="Select"
                            Errormessage="Error message"
                            errortext={false}
                            labelFixed="Relationship to {{MemberFirstName}}"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={8}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Mailing street address *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Apt/Floor (Optional)"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="City *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectField
                            label="State/Province"
                            helperText="Helper text"
                            Errormessage="Error message"
                            labelFixed="State/Province"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Zip/Postal code *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>



                <Grid item xs={12} md={12}>

                    <div className="InfoText">
                        <li>
                            <InfoRoundedIcon />
                            <p>orgname is faith based Muslim organization providing a broad platform for practicing and presenting Islam, supporting the development of Muslim communities and fostering dialogue, education, and civic engagement. <br /><br />Any Muslim adult living in North America who endeavors to practicing Islam as a total way of life may become a member of orgname</p>
                        </li>
                    </div>
                </Grid>
                <Grid item xs={12} md={12}>
                    <CheckBox
                        label="I agree to work with {orgname} and I also agree with its purpose to help the muslim community and together we all seek Allah's help, so that we can practice islam as a way of life."
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <CheckBox
                        label="I agree to complete Lifetime membership donation of $5,000.00 + as a one-time payment, or over 50 months [$100/monthly] period."
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <CheckBox
                        label="Mail or email me Membership Certificate."
                    />
                </Grid>




            </Grid>


        </div >
    );
}
export default EmergencyContact;


