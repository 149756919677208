import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SelectField, TextInputFixedLabel, PhoneInputField, InputButton } from "master-react-lib";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
function MemberInfo(props) {


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });


    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };



    return (

        <div className="FormContainer">


            <Grid container spacing={2}>

                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle'>Member details</h1>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="First name *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Last name *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone.number}
                            countryCodeEditable={false}
                            onChange={(phone, country) => console.log("onChange", phone, country)}
                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone number *"
                            LabelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Email address *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={8}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Mailing street address *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Apt/Floor (Optional)"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="City *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectField
                            label="State/Province"
                            helperText="Helper text"
                            Errormessage="Error message"
                            labelFixed="State/Province"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Zip/Postal code *"
                            labelStatus={true}
                        />
                    </div>
                </Grid>


            </Grid>


        </div >
    );
}
export default MemberInfo;


