import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PackageCard } from "master-react-lib";

function GeneralInfo(props) {


    const cardFunction = (value) => {
        (value) && console.log("clicked");
    };






    return (

        <div className="FormContainer">


            <Grid container spacing={2}>

                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle'>What is your preferred membership?</h1>
                </Grid>

                <Grid item md={4} sm={4} xs={4}>
                    <PackageCard
                        cardFunction={cardFunction}
                        dayText="Lifetime Membership"
                    />
                </Grid>

                <Grid item md={4} sm={4} xs={4}>
                    <PackageCard
                        cardFunction={cardFunction}
                        dayText="Premium Membership"
                    />
                </Grid>

                <Grid item md={4} sm={4} xs={4}>
                    <PackageCard
                        cardFunction={cardFunction}
                        dayText="General Membership"
                    />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <h2 className='formSubTitle'>What is your preferred membership?</h2>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <div className='FromList'>
                        <ul>
                            <li>The strategic imperatives of membership organizations.</li>
                            <li>Membership organizations also sponsor industry awards.</li>
                            <li>The Future Of Membership Organizations</li>
                            <li>The strategic imperatives of membership organizations.</li>
                            <li>Membership organizations also sponsor industry awards.</li>
                            <li>The Future Of Membership Organizations</li>
                        </ul>
                    </div>
                </Grid>



            </Grid>


        </div >
    );
}
export default GeneralInfo;


