import React from 'react';
import { NavBarOne  } from "master-react-lib";
import '../assets/css/style.css'

function Header() {



    return (
        <div>

            <NavBarOne 
                logo="https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-website-for-organization-masjid-tauheed-icnm-logo.webp"
                logoLink="/"
                background="#fff"
                maxWidth="1175px"
                LogoSize="220px"


                //// Button Style ////
                buttonLebel="DONATE NOW"
                ButtonLink="https://xd.adobe.com/view/a9342815-a66f-42cf-aee5-8dc16dcb70eb-fc67/specs/"
                target="blank"
                path="/"
                arrow={false}
                arrowColor="#fff"
                buttoncolor="#FF6200"
                width="auto"
                height="47px"
                border="#ccc 0px solid"
                borderRadius="4px"
                colorlabel="#fff"
                fontWeight="600"
                textAlign="center"
                handIconStatus={true}

            />
        </div>
    );
}


export default Header;